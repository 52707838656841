// sass-lint:disable no-vendor-prefixes
.input {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 193px;
	margin-bottom: 16px;

	&__input {
		font-size: 16px;
		line-height: 36px;
		height: 36px;
		padding: 0 8px;
		resize: none;
		outline: none;
		color: #000;
		appearance: none;
		background: #f5f9fe;
		border: 1px solid #28afb0;
		box-sizing: border-box;
		border-radius: 8px;

		&::placeholder {
			color: #1f271b;
			opacity: 0.2;
		}

		&::-ms-clear {
			display: none;
		}

		&_error {
			border-color: #f00;
		}
	}

	&_error-text {
		display: none;
	}

	&_error {
		.input__input {
			border-color: #f00;
		}

		.input__label {
			display: flex;
		}

		.input_error-text {
			display: block;
			margin-left: 20px;
			color: #eb5757;
			font-weight: 300;
			font-size: 12px;
			line-height: 15px;
			letter-spacing: 0.05em;
			text-transform: none;
			white-space: nowrap;
			position: absolute;
			bottom: -16px;
		}
	}
}

.popup-page {
	&__content {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2000;
		text-align: center;
	}

	&__body {
		background: #fff;
		border-radius: 16px;
		padding: 24px 43px;
		width: 220px;
		box-sizing: border-box;
	}

	&__name {
		font-size: 24px;
		white-space: nowrap;
		line-height: 34px;
		color: #000000;
		margin-bottom: 8px;
	}

	&__tel {
		text-decoration: none;
		white-space: nowrap;
		font-size: 16px;
		line-height: 18px;
		color: #000000;
	}

	&__logout {
		display: flex;
		align-items: center;
		color: #fff;
		justify-content: center;
		font-size: 20px;
		line-height: 24px;
		font-weight: 500;
		margin-top: 16px;

		svg {
			stroke: #fff;
			fill: none;
			stroke-width: 2px;
			margin-right: 8px;
		}
	}
}

.main {
	&__container {
		@include container;
		margin-top: 111px;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__logo {
		width: 125px;
		height: 108px;
		margin-bottom: 13px;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__logo-text {
		width: 134px;
		height: 25px;
		margin-bottom: 24px;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__button {
		background: rgba(31, 39, 27, .1);
		box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
		border-radius: 8px;
		width: 193px;
		height: 41px;
		font-size: 16px;
		line-height: 41px;
		color: rgba(25, 100, 126, .4);
		border: 0;
		font-weight: 600;
	}
}

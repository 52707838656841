.page {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	min-width: 320px;
	overflow-x: hidden;
	background: #f5f9fe;

	&__container {
		flex: 1 0 auto;
	}

	&__content {
		height: 100%;
	}

	&__footer {
		flex: 0 0 auto;
	}
}

// sass-lint:disable  no-important

@mixin container {
	box-sizing: border-box;
	min-width: 320px;
	padding: 0 16px;

	@media ($mediaTablet) {
		width: 720px;
		margin-left: auto;
		margin-right: auto;
		padding: 0;
	}

	@media ($mediaDesktop) {
		width: 1144px;
	}

	@media ($mediaDesktopLg) {
		width: 1530px;
	}
}

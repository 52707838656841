.choice {
	&__info {
		position: fixed;
		z-index: 2000;
		bottom: 8px;
		left: 8px;
		right: 8px;
		top: auto;
	}

	&__items {
		padding: 8px 12px;
		background: #f5f9fe;
		border-radius: 16px;
		overflow: auto;
		max-height: 71vh;
	}

	&__item {
		background: #f5f9fe;
		height: 44px;
		line-height: 44px;
		font-size: 20px;
		color: #000000;
		margin-bottom: 4px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-decoration: none;

		&:first-child {
			border-radius: 16px 16px 0 0;
		}

		&:last-child {
			border-radius: 0 0 16px 16px;
			margin-bottom: 0;
		}

		& + & {
			padding-top: 4px;
			position: relative;

			&::before {
				content: '';
				height: 1px;
				width: 100%;
				background: radial-gradient(98.81% 11088500.3% at 0% 99.8%, rgba(40, 175, 176, 0.4) 0%, rgba(40, 175, 176, 0) 100%);
				position: absolute;
				left: 0;
				top: 0;
			}
		}

		svg {
			stroke: #19647e;
			fill: none;
			stroke-width: 2px;
		}
	}

	&__button {
		background: #f5f9fe;
		border-radius: 16px;
		height: 57px;
		line-height: 57px;
		text-align: center;
		font-size: 20px;
		color: #28afb0;
		border: 0;
		font-weight: 500;
		width: 100%;
		margin-top: 16px;
	}
}

.market {
	margin-top: 19px;

	&__title {
		font-weight: 600;
		font-size: 20px;
		line-height: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #1f271b;
		margin-bottom: 3px;

		svg {
			margin-left: 9px;
			stroke: #19647e;
			fill: none;
			stroke-width: 2px;
			margin-top: 4px;
		}
	}

	&__logo {
		position: absolute;
		right: 22px;
		margin-top: 5px;

		svg {
			stroke: #1f271b;
		}
	}

	&__date {
		font-size: 16px;
		line-height: 22px;
		color: #000000;
		text-align: center;
		margin-bottom: 58px;
	}

	&__items {
		max-width: 359px;
		margin: 0 auto;
	}

	&__item {
		background: #fff;
		border: 1px solid #e5e5e5;
		border-radius: 8px;
		padding: 8px;
		display: flex;
		justify-content: space-between;

		& + & {
			margin-top: 8px;
		}
	}

	&__col {
		&_right {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
	}

	&__row {
		display: flex;
		align-items: center;

		& + & {
			margin-top: 8px;
		}
	}

	&__number {
		font-weight: 600;
		font-size: 18px;
		line-height: 25px;
		color: #000000;
		margin-bottom: 2px;
	}

	&__tel {
		font-size: 10px;
		line-height: 14px;
		color: #19647e;
		opacity: 0.34;
		margin-bottom: 5px;

		a {
			margin-left: 5px;
			text-decoration: none;
			color: #19647e;
		}
	}

	&__sum {
		font-size: 14px;
		line-height: 20px;
		color: #28afb0;
		font-weight: 600;
	}

	&__name {
		font-size: 14px;
		line-height: 14px;
		color: #19647e;
		margin-right: 8px;
	}

	&__status {
		border-radius: 8px;
		padding: 2px 8px;
		font-size: 10px;
		line-height: 1;
		color: #fff;
		white-space: nowrap;

		&_not-processed {
			background: #ffc700;
		}

		&_accepted {
			background: #60d6a9;
		}

		&_refused {
			background: #c7c7d4;
		}

		&_expects {
			background: #ffc700;
		}

		&_preparing {
			background: #ffa640;
		}

		&_ready {
			color: #19647e;
			background: #81fec6;
		}

		&_issued {
			background: #00bc29;
		}
	}
}

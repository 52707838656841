.order {
	margin: 19px auto;
	max-width: 359px;
	padding-bottom: 72px;

	&__title {
		font-weight: 600;
		font-size: 20px;
		line-height: 22px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #1f271b;
		margin: 0 auto 35px;
		max-width: 343px;

		svg {
			fill: #1f271b;
			stroke: none;
			stroke-width: 2px;
			margin-top: 4px;
		}
	}

	&__logo {
		svg {
			stroke: #1f271b;
			fill: none;
		}
	}

	&__table {
		max-width: 343px;
		margin: auto;
	}

	&__row {
		display: flex;
		justify-content: space-between;
		height: 18px;
		line-height: 18px;

		& + & {
			margin-top: 9px;
		}
	}

	&__col-name {
		font-size: 14px;
		color: #19647e;
		font-weight: 600;
	}

	&__col-value {
		font-size: 16px;
		color: #000;
	}

	&__client {
		color: #19647e;
		border-bottom: 1px solid;

		a {
			margin-left: 5px;
		}
	}

	&__total {
		max-width: 343px;
		margin: 8px auto 25px;
		display: flex;
		justify-content: space-between;
		color: #000;
		padding-top: 10px;
		border-top: 1px dashed rgba(0, 0, 0, .2);
		font-weight: 600;
	}

	&__total-text {
		text-transform: uppercase;
		font-size: 14px;
	}

	&__total-sum {
		font-size: 16px;
	}

	&__item {
		padding: 8px;
		border-radius: 8px;
		background: #fff;
		border: 1px solid #e5e5e5;

		& + & {
			margin-top: 8px;
		}

		&_active {
			.order__body {
				display: block;
			}
		}
	}

	&__header {
		display: flex;
		align-items: center;
	}

	&__header-img {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		margin-right: 8px;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__header-name {
		font-size: 14px;
		line-height: 20px;
		color: #19647e;
	}

	&__header-btn {
		margin-left: auto;
		font-size: 16px;
		height: 32px;
		padding: 0 32px;
		line-height: 32px;
		color: #fff;
		background: #00bc29;
		box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
		border-radius: 8px;
		text-align: center;
		font-weight: 600;
	}

	&__body {
		display: none;
		margin-top: 8px;
	}

	&__body-row {
		display: flex;
	}

	&__num {
		font-size: 16px;
		line-height: 18px;
		color: #000000;
		width: 10px;
		margin-right: 12px;
		min-width: 10px;
	}

	&__dish {
		width: 185px;
		margin-right: 29px;
		font-size: 16px;
		line-height: 18px;
		color: #000000;
		margin-bottom: 4px;
		font-weight: 600;
	}

	&__dish-extra {
		width: 185px;
		margin-right: 29px;
		font-size: 14px;
		line-height: 15px;
		margin-bottom: 8px;
		font-weight: 600;
	}

	&__number {
		font-size: 14px;
		line-height: 15px;
		margin-bottom: 8px;
		text-align: right;

		&_bold {
			font-size: 16px;
			line-height: 18px;
			font-weight: 600;
		}
	}

	&__sum {
		white-space: nowrap;
		font-size: 14px;
		line-height: 15px;
		margin-left: auto;

		&_md {
			font-size: 16px;
			line-height: 18px;
		}
	}

	&__body-item {
		& + & {
			margin-top: 8px;
			padding-top: 8px;
			border-top: 1px dashed rgba(0, 0, 0, .2);
		}
	}

	&__footer {
		margin-top: 8px;
		padding-top: 8px;
		border-top: 1px dashed rgba(0, 0, 0, .2);
	}

	&__button-wrap {
		background: #f5f9fe;
		box-shadow: 0 -2px 20px rgba(49, 67, 82, 0.18);
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 8px;
	}

	&__button {
		background: rgba(31, 29, 27, .1);
		box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
		border-radius: 8px;
		border: 0;
		height: 56px;
		line-height: 56px;
		margin: auto;
		max-width: 359px;
		font-weight: 600;
		width: 100%;
		font-size: 16px;
		color: rgba(25, 100, 126, .4);
		display: flex;
		justify-content: center;

		svg {
			display: none;
		}

		&_accept {
			background: #00bc29;
			color: #fff;
			align-items: center;

			svg {
				display: block;
				fill: #fff;
				margin-right: 12px;
			}
		}

		&_primary {
			background: #28afb0;
			color: #fff;
		}
	}
}

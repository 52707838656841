// sass-lint:disable force-pseudo-nesting, no-vendor-prefixes
html,
body {
	height: 100%;
	font-family: 'Arial', sans-serif;
	-webkit-tap-highlight-color: transparent;
	margin: 0;
}

button,
input {
	&:focus {
		outline: none;
	}
}

p,
h1,
h2,
h3,
ol,
ul {
	margin: 0;
}

.overflow-hidden {
	overflow: hidden;
}

.body-noscroll {
	overflow: hidden;
}

.overlay {
	opacity: 0;
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	z-index: 1000;
	transition: .3s;

	&_active {
		opacity: 1;
		visibility: visible;
	}
}

.nowrap {
	white-space: nowrap;
}

.owl-carousel:not(.owl-loaded) {
	opacity: 0;
	visibility: hidden;
	display: block;
}

@font-face {
  font-family: 'Futura New';
  src: url('FuturaNewLight-Reg.c3e4e517.eot');
  src: local('Futura New Light Reg'), local('FuturaNewLight-Reg'),
    url('FuturaNewLight-Reg.c3e4e517.eot#iefix') format('embedded-opentype'),
    url('FuturaNewLight-Reg.9d1d45c1.woff2') format('woff2'), url('FuturaNewLight-Reg.edbe4070.woff') format('woff'),
    url('FuturaNewLight-Reg.9526e345.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New';
  src: url('FuturaNewBold-Reg.804ccc84.eot');
  src: local('Futura New Bold Reg'), local('FuturaNewBold-Reg'),
    url('FuturaNewBold-Reg.804ccc84.eot#iefix') format('embedded-opentype'),
    url('FuturaNewBold-Reg.7b608f5d.woff2') format('woff2'), url('FuturaNewBold-Reg.cd994ed8.woff') format('woff'),
    url('FuturaNewBold-Reg.ce513915.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New';
  src: url('FuturaNewBook-Reg.08ed4795.eot');
  src: local('Futura New Book Reg'), local('FuturaNewBook-Reg'),
    url('FuturaNewBook-Reg.08ed4795.eot#iefix') format('embedded-opentype'),
    url('FuturaNewBook-Reg.24b9e46b.woff2') format('woff2'), url('FuturaNewBook-Reg.b7171b3b.woff') format('woff'),
    url('FuturaNewBook-Reg.f5a339aa.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
  font-family: 'Futura New', 'Arial', sans-serif;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
}

button:focus,
input:focus {
  outline: none;
}

p,
h1,
h2,
h3,
ol,
ul {
  margin: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.body-noscroll {
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.owl-carousel:not(.owl-loaded) {
  opacity: 0;
  visibility: hidden;
  display: block;
}

.main__container {
  box-sizing: border-box;
  min-width: 320px;
  padding: 0 16px;
  margin-top: 111px;
}

@media (min-width: 768px) {
  .main__container {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .main__container {
    width: 1144px;
  }
}

@media (min-width: 1920px) {
  .main__container {
    width: 1530px;
  }
}

.main__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main__logo {
  width: 125px;
  height: 108px;
  margin-bottom: 13px;
}

.main__logo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.main__logo-text {
  width: 134px;
  height: 25px;
  margin-bottom: 24px;
}

.main__logo-text img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.main__button {
  background: rgba(31, 39, 27, 0.1);
  box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 193px;
  height: 41px;
  font-size: 16px;
  line-height: 41px;
  color: rgba(25, 100, 126, 0.4);
  border: 0;
  font-weight: 600;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 320px;
  overflow-x: hidden;
  background: #f5f9fe;
}

.page__container {
  flex: 1 0 auto;
}

.page__content {
  height: 100%;
  margin-bottom: 36px;
}

.page__footer {
  flex: 0 0 auto;
}

.input {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 193px;
  margin-bottom: 16px;
}

.input__input {
  font-size: 16px;
  line-height: 36px;
  height: 36px;
  padding: 0 8px;
  resize: none;
  outline: none;
  color: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f5f9fe;
  border: 1px solid #28afb0;
  box-sizing: border-box;
  border-radius: 8px;
}

.input__input::-moz-placeholder {
  color: #1f271b;
  opacity: 0.2;
}

.input__input:-ms-input-placeholder {
  color: #1f271b;
  opacity: 0.2;
}

.input__input::placeholder {
  color: #1f271b;
  opacity: 0.2;
}

.input__input::-ms-clear {
  display: none;
}

.input__input_error {
  border-color: #f00;
}

.input_error-text {
  display: none;
}

.input_error .input__input {
  border-color: #f00;
}

.input_error .input__label {
  display: flex;
}

.input_error .input_error-text {
  display: block;
  margin-left: 20px;
  color: #eb5757;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: none;
  white-space: nowrap;
  position: absolute;
  bottom: -16px;
}

.choice__info {
  position: fixed;
  z-index: 2000;
  bottom: 8px;
  left: 8px;
  right: 8px;
  top: auto;
}

.choice__items {
  padding: 8px 12px;
  background: #f5f9fe;
  border-radius: 16px;
  overflow: auto;
  max-height: 71vh;
}

.choice__item {
  background: #f5f9fe;
  height: 44px;
  line-height: 24px;
  font-size: 20px;
  color: #000000;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.choice__item:first-child {
  border-radius: 16px 16px 0 0;
}

.choice__item:last-child {
  border-radius: 0 0 16px 16px;
  margin-bottom: 0;
}

.choice__item + .choice__item {
  padding-top: 4px;
  position: relative;
}

.choice__item + .choice__item::before {
  content: '';
  height: 1px;
  width: 100%;
  background: radial-gradient(98.81% 11088500.3% at 0% 99.8%, rgba(40, 175, 176, 0.4) 0%, rgba(40, 175, 176, 0) 100%);
  position: absolute;
  left: 0;
  top: 0;
}

.choice__item svg {
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
}

.choice__button {
  background: #f5f9fe;
  border-radius: 16px;
  height: 57px;
  line-height: 57px;
  text-align: center;
  font-size: 20px;
  color: #28afb0;
  border: 0;
  font-weight: 500;
  width: 100%;
  margin-top: 16px;
}

.market {
  margin-top: 19px;
}

.market__title {
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1f271b;
  margin-bottom: 3px;
  position: relative;
}

.market__title svg {
  margin-left: 9px;
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
  margin-top: 4px;
}

.market__logo {
  position: absolute;
  right: 22px;
  margin-top: 5px;
}

.headerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerContainer span {
  text-align: center;
  max-width: 55%;
}

.headerContainer svg {
  margin-top: 9px;
  display: flex;
}

.market__logo svg {
  stroke: #1f271b;
}

.market__date {
  font-size: 16px;
  line-height: 22px;
  color: #60d6a9;
  text-align: center;
  margin-bottom: 10px;
}

.market__items {
  max-width: 359px;
  margin: 0 auto;
}

.market__item {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
}

.market__item + .market__item {
  margin-top: 8px;
}

.market__col_right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.corner__list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 4px;
}

.order__status-time {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.order__status-small {
  font-size: 12px;
  line-height: 1;
  padding-right: 50px;
}

.order__status-waiting {
  color: #ffc700;
}

.order__status-confirmed {
  color: #60d6a9;
}

.order__status-refused {
  color: #c7c7d4;
}

.order__status-cooking {
  color: #ffa640;
}

.order__status-ready {
  color: #81fec6;
}

.order__status-waiting_on_counter {
  color: #717179;
}

.order__status-issued_courier {
  color: #717179;
}

.order__status-received {
  color: #00bc29;
}

.order__time {
  font-weight: 200;
  font-size: 12px;
  line-height: 1;
  color: #000000;
  /* margin-bottom: 2px; */
}

.market__row {
  display: flex;
  align-items: center;
}

.market__row + .market__row {
  margin-top: 4px;
}

.market__number {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 2px;
}

.market__tel {
  font-size: 10px;
  line-height: 14px;
  color: #19647e;
  opacity: 0.5;
  margin-bottom: 5px;
}

.market__tel a {
  margin-left: 5px;
  text-decoration: none;
  color: #19647e;
}

.market__sum {
  font-size: 14px;
  line-height: 20px;
  color: #28afb0;
  font-weight: 500;
}

.market__name {
  font-size: 14px;
  line-height: 14px;
  color: #19647e;
  margin-right: 8px;
}

.order__eat_place {
  font-size: 12px;
  line-height: 14px;
  color: #19647e;
  opacity: 0.7;
  margin-bottom: 5px;
}

.market__status {
  border-radius: 8px;
  padding: 2px 8px;
  font-size: 10px;
  line-height: 1;
  color: #fff;
  white-space: nowrap;
}

.market__status_waiting {
  background: #ffc700;
}

.market__status_confirmed {
  background: #60d6a9;
}

.market__status_refused {
  background: #c7c7d4;
}

.market__status_expects {
  background: #ffc700;
}

.market__status_cooking {
  background: #ffa640;
}

.market__status_ready {
  color: #19647e;
  background: #81fec6;
}

.market__status_received {
  background: #00bc29;
}

.market__status_issued_courier {
  background: #555a57;
}

.order__status {
  border-radius: 8px;
  padding: 2px 8px;
  font-size: 16px;
  line-height: 1;
  white-space: nowrap;
}

.order__status_waiting {
  color: #19647e;
  background: #ffc700;
}

.order__status_confirmed {
  color: #fff;
  background: #60d6a9;
}

.order__status_refused {
  color: #19647e;
  background: #c7c7d4;
}

.order__status_cooking {
  color: #19647e;
  background: #ffa640;
}

.order__status_ready {
  color: #19647e;
  background: #81fec6;
}

.order__status_readybtn {
  color: #fff;
  background: #00bc29;
}

.order__status_issued {
  color: #19647e;
  background: #c7c7d4;
}

.order__status_received {
  color: #00bc29;
  background: ##fff;
}

.order__status_cancelled {
  color: #19647e;
  background: #c7c7d4;
}

.order__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1f271b;
  margin-bottom: 10px;
  max-width: 100%;
}

.order__title svg {
  fill: #1f271b;
  stroke: none;
  stroke-width: 2px;
  margin-left: 8px;
}

.order__logo svg {
  stroke: #1f271b;
  fill: none;
}

.order__table {
  max-width: 343px;
  margin: auto;
}

.order__total {
  max-width: 343px;
  margin: 8px auto 25px;
  display: flex;
  justify-content: space-between;
  color: #000;
  padding-top: 10px;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  font-weight: 600;
}

.order__total-text {
  text-transform: uppercase;
  font-size: 14px;
}

.order__total-sum {
  font-size: 16px;
}

/* .order__item {
	padding: 8px;
	border-radius: 8px;
	background: #fff;
	border: 1px solid #e5e5e5;
} */

.order__item + .order__item {
  margin-top: 8px;
}

.order__item_active .order__body {
  display: block;
}

.order__header {
  display: flex;
  align-items: center;
}

.order__header-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}

.order__header-img img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.order__header-name {
  font-size: 16px;
  line-height: 20px;
  color: #19647e;
}

.order__header-btn {
  margin-left: auto;
  font-size: 14px;
  height: 18px;
  padding: 0 32px;
  box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 9px;
  text-align: center;
  font-weight: 500;
}

.order__body {
  display: none;
  margin-top: 8px;
}

.order__body-row {
  display: flex;
}

.order__num {
  font-size: 16px;

  color: #000000;
  width: 10px;
  margin-right: 12px;
  min-width: 10px;
  margin-bottom: 2px;
}

.order__dish {
  margin-right: 29px;
  font-size: 16px;
  width: 170px;
  color: #000000;
  margin-bottom: 2px;
  font-weight: 500;
}

.order__dish-extra {
  width: 170px;
  margin-right: 29px;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  font-weight: 300;
}

.order__number {
  font-size: 14px;

  margin-bottom: 2px;
  text-align: right;
}

.order__number-extra {
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  text-align: right;
  font-weight: 300;
}

.order__number_bold {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
}

.order__sum {
  white-space: nowrap;
  font-size: 14px;

  margin-left: auto;
  margin-bottom: 2px;
}

.order__sum-extra {
  white-space: nowrap;
  font-size: 14px;
  line-height: 15px;
  margin-left: auto;
  font-weight: 300;
  margin-bottom: 2px;
}

.order__sum_md {
  font-size: 16px;
  line-height: 18px;
}

.order__itemnote {
  font-size: 16px;
  line-height: 18px;
  padding-left: 25px;
  color: #19647e;
  font-style: italic;
}

.order__body-item + .order__body-item {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
}

.order__footer {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
}

.order__button_primary {
  background: #28afb0;
  color: #fff;
}

.popup-page__content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  text-align: center;
}

.popup-page__body {
  background: #fff;
  border-radius: 16px;
  padding: 24px 43px;
  width: 220px;
  box-sizing: border-box;
}

.popup-page__name {
  font-size: 24px;
  white-space: nowrap;
  line-height: 34px;
  color: #000000;
  margin-bottom: 8px;
}

.popup-page__logout {
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 16px;
}

.popup-page__logout svg {
  stroke: #fff;
  fill: none;
  stroke-width: 2px;
  margin-right: 8px;
}


/*# sourceMappingURL=index.09ae2566.css.map */
